/** @jsx jsx */
import { graphql } from "gatsby"
import { Fragment } from "react"

import { jsx } from "@trueskin-web/theme"

import BeforeAfter from "../block-library/before-after"
import Blocks from "../block-library/components/blocks"
import SEODetails from "../block-library/components/seo-details"
import ContentCta from "../block-library/content-cta"
import Doctors from "../block-library/doctors"
import Hero from "../block-library/hero"
import Process from "../block-library/process"
import ProductsHighlight from "../block-library/products-highlight"
import Reviews from "../block-library/reviews"
import Layout from "../components/layout"

const HowItWorksPage = ({
  data: {
    strapi: { howItWorksPage },
  },
}) => (
  <Layout title={howItWorksPage.title}>
    <SEODetails title={howItWorksPage.title} {...howItWorksPage.seoDetails} />

    {howItWorksPage.blocks?.length ? (
      <Blocks blocks={howItWorksPage.blocks} />
    ) : (
      <Fragment>
        <Hero {...howItWorksPage.hero} />
        <Process {...howItWorksPage.process} />
        <ProductsHighlight {...howItWorksPage.productsHighlight} />
        <ContentCta {...howItWorksPage.contentCta} />
        <Doctors withMoreLink {...howItWorksPage.doctors} />
        <BeforeAfter {...howItWorksPage.beforeAfter} />
        <Reviews {...howItWorksPage.reviews} />
      </Fragment>
    )}
  </Layout>
)

export const query = graphql`
  {
    strapi {
      howItWorksPage {
        title
        seoDetails {
          ...SEODetailsFragment
        }
        hero {
          ...HeroFragment
        }
        productsHighlight {
          ...ProductsHighlightFragment
        }
        process {
          ...ProcessFragment
        }
        contentCta {
          ...ContentCtaFragment
        }
        doctors {
          ...DoctorsFragment
        }
        beforeAfter {
          ...BeforeAfterFragment
        }
        reviews {
          ...ReviewsFragment
        }
        blocks {
          __typename
          ... on Strapi_ComponentBlocksHero {
            ...HeroFragment
          }
          ... on Strapi_ComponentBlocksSmallHero {
            ...SmallHeroFragment
          }
          ... on Strapi_ComponentBlocksTextHero {
            ...TextHeroFragment
          }
          ... on Strapi_ComponentLandingContentCtaList {
            ...ContentCtaListFragment
          }
          ... on Strapi_ComponentLandingList {
            ...ListFragment
          }
          ... on Strapi_ComponentLandingContentCtaBadges {
            ...ContentCtaBadgesFragment
          }
          ... on Strapi_ComponentLandingBadges {
            ...BadgesFragment
          }
          ... on Strapi_ComponentBlocksProcess {
            ...ProcessFragment
          }
          ... on Strapi_ComponentBlocksHighlights {
            ...HighlightsFragment
          }
          ... on Strapi_ComponentBlocksContentCta {
            ...ContentCtaFragment
          }
          ... on Strapi_ComponentBlocksContentVideo {
            ...ContentVideoFragment
          }
          ... on Strapi_ComponentBlocksPrStrip {
            ...PrStripFragment
          }
          ... on Strapi_ComponentAtomsImage {
            ...ImageFragment
          }
          ... on Strapi_ComponentAtomsSpace {
            ...SpaceFragment
          }
          ... on Strapi_ComponentAtomsRichContent {
            ...RichContentFragment
          }
          ... on Strapi_ComponentBlocksReviews {
            ...ReviewsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorks {
            ...HowItWorksFragment
          }
          ... on Strapi_ComponentBlocksDoctorsIntro {
            ...DoctorsIntroFragment
          }
          ... on Strapi_ComponentBlocksProductsHighlight {
            ...ProductsHighlightFragment
          }
          ... on Strapi_ComponentBlocksBeforeAfter {
            ...BeforeAfterFragment
          }
          ... on Strapi_ComponentBlocksMirroredHighlights {
            ...MirroredHighlightsFragment
          }
          ... on Strapi_ComponentBlocksPrefooter {
            ...PrefooterFragment
          }
          ... on Strapi_ComponentBlocksDoctors {
            ...DoctorsFragment
          }
          ... on Strapi_ComponentBlocksShowcaseProducts {
            ...ShowcaseProductsFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksVisually {
            ...HowItWorksVisuallyFragment
          }
          ... on Strapi_ComponentBlocksFullSizeHero {
            ...FullSizeHeroFragment
          }
          ... on Strapi_ComponentBlocksLpHero {
            ...LpHeroFragment
          }
          ... on Strapi_ComponentBlocksTreatmentGoals {
            ...TreatmentGoalsFragment
          }
          ... on Strapi_ComponentBlocksDoctorsBoard {
            ...DoctorsBoardFragment
          }
          ... on Strapi_ComponentBlocksFaqs {
            ...FaqsFragment
          }
          ... on Strapi_ComponentBlocksConditionsList {
            ...ConditionsListFragment
          }
          ... on Strapi_ComponentBlocksHowItWorksGuide {
            ...HowItWorksGuideFragment
          }
          ... on Strapi_ComponentBlocksMedicalIntro {
            ...MedicalIntroFragment
          }
          ... on Strapi_ComponentBlocksSkinGuideIndex {
            ...SkinGuideIndexFragment
          }
          ... on Strapi_ComponentBlocksSetIntro {
            ...SetIntroFragment
          }
          ... on Strapi_ComponentBlocksHeroTrustpilot {
            ...HeroTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksHeroPriceTrustpilot {
            ...HeroPriceTrustpilotFragment
          }
          ... on Strapi_ComponentBlocksProgressBoard {
            ...ProgressBoardFragment
          }
          ... on Strapi_ComponentBlocksInstagramWidget {
            ...InstagramWidgetFragment
          }
          ... on Strapi_ComponentBlocksSocialReviews {
            ...SocialReviewsFragment
          }
          ... on Strapi_ComponentBlocksBannerUsp {
            ...BannerUspFragment
          }
          ... on Strapi_ComponentBlocksMedicHero {
            ...HeroMedicFragment
          }
          ... on Strapi_ComponentBlocksDoctorTeam {
            ...DoctorTeamFragment
          }
          ... on Strapi_ComponentBlocksDoctorMembers {
            ...DoctorMembersFragment
          }
          ... on Strapi_ComponentBlocksTitle {
            ...TitleFragment
          }
          ... on Strapi_ComponentBlocksThreePartContent {
            ...ThreePartContentFragment
          }
          ... on Strapi_ComponentBlocksZigZag {
            ...ZigZagFragment
          }
          ... on Strapi_ComponentBlocksConnector {
            ...ConnectorFragment
          }
          ... on Strapi_ComponentBlocksTimeline {
            ...TimelineFragment
          }
          ... on Strapi_ComponentBlocksProductCards {
            ...ProductCardsFragment
          }
          ... on Strapi_ComponentBlocksIngredientCards {
            ...IngredientCardsFragment
          }
          ... on Strapi_ComponentBlocksCtaBlock {
            ...CtaBlockFragment
          }
        }
      }
    }
  }
`

export default HowItWorksPage
